@mixin aspect-ratio($ratio) {
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-top: $ratio * 100%;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > img {
    object-fit: cover;
  }
}

// Source: https://stackoverflow.com/questions/12728634/string-replace-in-sass
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

/*
  Make a custom dashed border using svg background
  Source: https://kovart.github.io/dashed-border-generator/
*/
@mixin custom-dashed-border(
  $stroke-width: 4, $dash-length: 6, $space-length: 14,
  $line-cap: 'square', $dash-offset: 0, $border-radius: 0,
  $color: '#000000'
) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='#{$border-radius}' ry='#{$border-radius}' stroke='#{str-replace(#{$color}, '#', '%23')}' stroke-width='#{$stroke-width}' stroke-dasharray='#{$dash-length}%2c #{$space-length}' stroke-dashoffset='#{$dash-offset}' stroke-linecap='#{$line-cap}'/%3e%3c/svg%3e");
  border-radius: $border-radius * 1px;
}

@use 'base' as *;
@use 'css-icons' as *;

$bg-color-light: lighten($bluePrimary, 78);
$bg-color-dark: lighten($bluePrimary, 76);

.ng-select {
  .ng-select-container {
    border-radius: 0;
    border-color: $grey50;
    min-height: rem-calc(60);

    .ng-value-container {
      padding-left: rem-calc(24);

      .ng-placeholder {
        color: $grey80;
        text-transform: uppercase;
        font-weight: 500;
        font-size: rem-calc(13);
      }
    }
  }

  .ng-arrow-wrapper {
    padding-right: rem-calc(20);
    width: auto;
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        display: flex;
        align-items: center;
        color: $grey80;
        text-transform: uppercase;
        font-size: rem-calc(13);
        font-weight: 500;
        padding: rem-calc(14) rem-calc(20);

        &.ng-option-selected {
          color: $bluePrimary;
          background-color: $bg-color-dark;
        }

        &.ng-option-marked {
          color: $bluePrimary;
          background-color: $bg-color-light;
        }

        &.ng-option-selected.ng-option-marked {
          background-color: $bg-color-dark;
        }
      }
    }
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    .ng-value-container {
      padding-left: rem-calc(20);

      .ng-value {
        .ng-value-label {
          color: $grey80;
          text-transform: uppercase;
          font-weight: 500;
          font-size: rem-calc(13);
        }
      }

      .ng-input {
        top: auto;
        padding-left: rem-calc(20);
      }
    }
  }

}

.ng-select.ng-select-multiple {
  .ng-select-container {
    .ng-value-container {
      padding-left: rem-calc(20);

      .ng-value {
        background-color: $bg-color-dark;
        color: $bluePrimary;
        text-transform: uppercase;
        font-size: rem-calc(13);
        font-weight: 500;
        line-height: 0.9rem;
      }

      .ng-placeholder {
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0;
      }
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        display: flex;
        align-items: center;

        & > * {
          min-width: 0;
          flex: 0 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        input[type='checkbox'] {
          flex: 0 0 auto;
          margin-right: rem-calc(12);
        }
      }
    }
  }
}

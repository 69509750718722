@use 'base' as *;

// FIELDSET
fieldset {
  border: none;
  padding-block-start: rem-calc(16);
  padding-block-end: rem-calc(17);
  padding-inline: 0;
  margin-inline: 0;
  position: relative;

  &.required {
    legend:after {
      content: ' *';
      color: $gold;
    }
  }

  &.invalid {
    [data-error] {
      color: $orange;
    }

    input:not([type="radio"]),
    textarea {
      border-color: $orange;
    }

    .ng-select {
      .ng-select-container {
        border-color: $orange;
      }
    }

    input[type="file"][id="file"] + label[for="file"] div {
      color: $orange;
    }
  }

  legend {
    color: $grey80;
    text-transform: uppercase;
    font-size: rem-calc(13);
    font-weight: 500;
  }

  [data-error] {
    /* TODO: handle multiple error messages at once. Is rare though. */
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: rem-calc(12);
    color: $grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &:not(.show) {
      display: none;
    }
  }

  input:not([type="radio"]),
  textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid $grey50;
    color: $grey80;
    padding: rem-calc(20) rem-calc(32);
    font-weight: 500;
    font-size: rem-calc(14);

    &::placeholder {
      color: $grey;
    }
  }

  textarea {
    resize: vertical;
  }

  .ng-select  {
    .ng-select-container .ng-value-container {
      padding-left: rem-calc(32);

      .ng-placeholder {
        color: $grey;
        text-transform: initial;
        font-size: rem-calc(14);
      }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      text-transform: initial;
    }
  }

  .ng-select.ng-select-single {
    .ng-select-container .ng-value-container .ng-value .ng-value-label {
      text-transform: initial;
      font-size: rem-calc(14);
    }
  }
}


// CHECKBOX

input[type='checkbox'] {
  $size: rem-calc(20);

  all: unset;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: $size;
  height: $size;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $white;
    border: 1px solid $grey50;
    transition: background-color 0.2s ease-in-out,
                border-color 0.2s ease-in-out;
  }

  $after-transform: rotate(45deg);
  &:after {
    content: '';
    height: 50%;
    width: 25%;
    border: solid transparent;
    border-width: 0 $size/10 $size/10 0;
    position: absolute;
    top: 19%;
    left: 37.5%;
    transform: $after-transform scale(0);
    transition: transform 0.2s ease-in-out,
                border-color 0.2s ease-in-out;
  }

  &:checked {
    &:before {
      background-color: $bluePrimary;
      border-color: $bluePrimary;
    }

    &:after {
      transform: $after-transform scale(1);
      border-color: $white;
    }
  }
}

// RADIO
// TODO: in the future refactor radio into its own component,
//       layout might get more complicated when doing responsive for example
input[type="radio"] {
  $size: rem-calc(20);
  $padding: rem-calc(26);

  /* Remove browser styles */
  appearance: none;
  background-color: transparent;
  margin: 0;

  width: $size;
  height: $size;
  border: rem-calc(1) solid $grey50;
  border-radius: 50%;
  transition: border ease-in-out 0.15s;
  align-self: center;
  margin-left: $padding;
  z-index: 1;
  cursor: pointer;

  & + label {
    flex: 1 0;
    text-transform: uppercase;
    font-size: rem-calc(14);
    font-weight: 500;
    color: $bluePrimary;
    padding: $padding;
    padding-left: $padding + $size + rem-calc(22);
    margin-left: -$padding - $size;
    border-radius: rem-calc(100);
    border: rem-calc(1) solid $grey50;
    transition: {
      property: background-color, border-color;
      timing-function: ease-in-out;
      duration: 0.15s;
    };
    cursor: pointer;
    align-self: center;

    &:not(:last-child) {
      margin-right: $padding;
    }
  }

  &:checked {
    border-color: $blueSecondary;
    border-width: rem-calc(7);

    & + label {
      color: $white;
      background-color: $bluePrimary;
      border-color: $bluePrimary;
    }
  }

  &:focus {
    outline: rem-calc(2) solid rgba($blueSecondary, 0.25);
    outline-offset: rem-calc(2);
  }

  &:disabled {
    opacity: 0.6;

    & + label {
      opacity: 0.6;
      cursor: initial;
    }
  }
}

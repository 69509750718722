@use 'functions/rem-calc' as *;

.loading-animation-blur {
  animation: blurLoading 1.5s infinite;
  pointer-events: none;
}

@keyframes blurLoading {
  0% { filter: blur(#{rem-calc(4)}); }
  50% { filter: blur(#{rem-calc(6)}); }
  100% { filter: blur(#{rem-calc(4)}); }
}
@use 'functions/rem-calc' as *;
@use 'variables/fonts' as *;
@use 'variables/colors' as *;
@use 'mixins/breakpoints' as *;

@font-face {
  font-family: FuturaPt;
  font-weight: 400;
  src: url("/assets/fonts/FuturaPt/FUTURAPTBOOK.OTF") format("opentype");
}

@font-face {
  font-family: FuturaPt;
  font-weight: 500;
  src: url("/assets/fonts/FuturaPt/FUTURAPTMEDIUM.OTF") format("opentype");
}

@font-face {
  font-family: FuturaPt;
  font-weight: 600;
  src: url("/assets/fonts/FuturaPt/FUTURAPTHEAVY.OTF") format("opentype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url("/assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url("/assets/fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url("/assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  src: url("/assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf") format("truetype");
}

html, body {
  font-family: $FuturaPt;
  font-size: 16px;
}

p {
  line-height: 1.4;
  font-family: $OpenSans;
}

a {
  color: $blueSecondary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1, h2, h3, h4 {
  font-weight: 500;
  line-height: 1.4;
}

h1 {
  font-size: rem-calc(26);
  
  @include lg {
    font-size: rem-calc(32);
  }
  
  @include xxl {
    font-size: rem-calc(42);
  }
}

h2 {
  font-size: rem-calc(30);
}

h3 {
  font-size: rem-calc(22);
}

h4 {
  font-size: rem-calc(15);
}

small {
  font-size: rem-calc(13);
}

@use 'base' as *;

.modal {
  &-close {
    all: unset;
    display: flex;
    cursor: pointer;
    padding: rem-calc(18);
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(10);
    background: $white;
    z-index: 1;

    app-icon {
      width: rem-calc(14);
      height: rem-calc(14);
    }
  }
}

@use 'css-icons' as css-icons;
@use 'buttons' as buttons;
@use 'fonts' as fonts;
@use 'widgets' as widgets;
@use 'ng-select' as ng-select;
@use 'inputs' as inputs;
@use 'loading' as loading;
@use 'modal' as modal;
@use 'swiper' as swiper;
@use 'variables/colors' as colors;
@use 'functions/rem-calc' as rem-calc;

// Bootstrap grid
@import '~bootstrap/scss/bootstrap-grid';
@import 'bootstrap-extends';


// Swiper
@import '~swiper/scss';
@import '~swiper/scss/pagination';

// ng-select
@import '~@ng-select/ng-select/themes/default.theme.css';

// ngx-simple-modal
@import '~ngx-simple-modal/styles/simple-modal.scss';

*, *:before, *:after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

.admin-link-wrapper {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    box-shadow: inset rem-calc.rem-calc(0 0 0 2) colors.$blueSecondary;
  }

  .admin-link {
    position: absolute;
    z-index: 2;
    background-color: colors.$blueSecondary;
    color: colors.$white;
    padding: rem-calc.rem-calc(6 10);
    text-transform: uppercase;
    cursor: pointer;
    font: {
      size: rem-calc.rem-calc(14);
      weight: 500;
    }

    &:hover {
      background-color: darken(colors.$blueSecondary, 4%);
    }

    &.top {
      top: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.left {
      left: 0;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
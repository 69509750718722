@use 'variables/colors' as *;
@use 'functions/rem-calc' as *;
@use 'css-icons' as *;

$blueGradient: linear-gradient(50deg, $bluePrimary -40%, $blueSecondary 100%);

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: rem-calc(16 20 14 20);
  line-height: 1;
  width: auto;
  outline: none;
  border: none;
  color: $white;
  cursor: pointer;
  transition: background-color .2s ease, color .1s ease, opacity .1s ease;
  background-color: $blueLight;
  font: {
    size: rem-calc(14);
    weight: 600;
  }
  text: {
    transform: uppercase;
    decoration: none;
  }

  app-icon {
    width: rem-calc(16);
    height: rem-calc(16);
    margin-top: rem-calc(-2);
  }

  &:hover {
    text-decoration: none;
  }


  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }

  &.btn-text-left {
    justify-content: flex-start;
  }

  &.btn-show-arrow {
    position: relative;
    padding-right: rem-calc(35);

    &:after {
      content: '';
      @include angle-right;
      position: absolute;
      right: rem-calc(15);
      margin: {
        top: -1px;
      }
    }
  }

  &.w-100 {
    width: 100%;
  }
}

.btn-gradient-blue {
  background: $blueGradient;
  transition: background-size .3s ease;
  background: {
    position: right;
    size: 101%;
  }
  
  &:hover {
    background: {
      size: 115%;
    }
  }
}

.btn-hover-gradient-blue {
  position: relative;
  background: $blueGradient;
  z-index: 0;

  & > * {
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $blueLight;
    z-index: -1;
    pointer-events: none;
    transition: opacity .1s ease;
  }

  &:hover {
    &:before {
      opacity: 0;
    }
  }
}

.btn-pink {
  background-color: $pink;
  color: $white;
}

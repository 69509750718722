@use 'base' as *;

@mixin caret-down {
  width: 0; 
  height: 0; 
  border-left: rem-calc(3) solid transparent;
  border-right: rem-calc(3) solid transparent;
  border-top: rem-calc(3) solid $white;
}

@mixin angle-right {
  display: inline-block;
  border: solid $white;
  border-width: rem-calc(0 2 2 0);
  padding: rem-calc(3);
  transform: rotate(-45deg);
}

@mixin slash($thickness: rem-calc(3)) {
  display: inline-block;
  width: $thickness;
  height: rem-calc(12);
  transform: skew(-15deg);
  background-color: $blueSecondary;
}

.caret-down {
  @include caret-down;
}

.angle-right {
  @include angle-right;
}

.slash {
  @include slash;
}

.thin-slash {
  @include slash(rem-calc(2));
}

$black: #181918;
$white: #fff;

$bluePrimary: #002F67;
$blueSecondary: #00B4E3;
$blueLight: #0D3F7B;

$gold: #B49660;

$grey20: #F7F7FA;
$grey50: #E3E4E7;
$grey: #BDBEC0;
$grey80: #58585A;

$pink: #FF1C60;
$orange: #FF8049;
$green: #81BF6B;
$sky: #72CEF9;

$borderDefault: rgba($white, .1);

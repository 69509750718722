@use 'base' as *;
@use 'css-icons' as *;

.swiper {
  padding-bottom: rem-calc(60);

  &-slide {
    display: flex;
    height: auto !important;
  }

  &-pagination {
    &-bullet {
      width: rem-calc(6);
      height: rem-calc(6);
      background-color: rgba($white, .6);

      &-active {
        background-color: $blueSecondary;
      }
    }
  }

  &-button {

    &-prev,
    &-next {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(30);
      height: rem-calc(30);
      margin: 0;
      border: rem-calc(2) solid $borderDefault;
      border-radius: 50%;
      top: auto;
      bottom: rem-calc(85);
      cursor: pointer;
      position: absolute;
      transition: background-color .1s ease, opacity .1s ease;
      z-index: 2;

      &:not(.swiper-button-disabled) {
        &:hover {
          background-color: rgba($white, .03);
        }
      }

      &:after {
        content: '';
        @include angle-right;
      }
    }

    &-prev {
      left: auto;
      right: 51%;

      &:after {
        transform: rotate(135deg);
        margin-right: rem-calc(-2);
      }
    }

    &-next {
      right: auto;
      left: 51%;

      &:after {
        margin-left: rem-calc(-2);
      }
    }

    &-disabled {
      cursor: default;
      opacity: .4;
    }
  }
}
